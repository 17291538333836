import * as yup from "yup";
import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { ScrollToError, reformatDateString, simulateBrowserBack } from '../Components/Utilities';
import { IntroSection, NameAndContactSection, ConsentSection, DemographicSection } from '../Components/FormSections';
import { ValidationRegex, ValidationRules } from './Common';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import QRCode from "../Components/QRCode";
import { ThreeDots } from 'react-loader-spinner';
import { sendQRCodeViaEmail } from './CloudFunctions'

const schema = yup.object().shape({
  firstName: yup.string().matches(ValidationRegex.name, 'Name cannot contain illegal characters such as #!/$?').required(),
  lastName: yup.string().matches(ValidationRegex.name, 'Name cannot contain illegal characters such as #!/$?').required(),
  dob: ValidationRules.dobRule,
  email: ValidationRules.emailRule,
  phone: ValidationRules.phoneRule,
  address: ValidationRules.addressRule,
  suite: yup.string().optional(),
  city: yup.string().matches(ValidationRegex.city, 'City name contains non-alphabets').required("City is required."),
  state: yup.string().matches(ValidationRegex.state, 'Must be a valid state choice').required("State is required."),
  zip: yup.string().matches(ValidationRegex.zip, 'Only 5 digit numerical zip is allowed.').required("Zip code is required."),
  gender: yup.string().required(),
  race: yup.string().required(),
  ethnicity: yup.string().required(),
  pregnancy: yup.string().optional(),
  guardian: yup.string().matches(/^(?!.*No).*/, 'We cannot proceed without your consent.').optional(),
  disability: yup.string().required(),
  preferredLanguage: yup.string().required(),
  employmentType: yup.string().optional(),
  homeless: yup.bool().optional(),
  county_email: yup.bool().optional(),
  email_encryption: yup.bool().optional(),
  consent1: yup.bool().required().oneOf([true], 'You must consent to the HIPAA data use authorization to continue'),
  consent2: yup.bool().required().oneOf([true], 'You must accept the release of liability to continue')
});

// converts data into a JSON serialized string with a version header
function CountyJSONSerializer(data) {
  console.log(JSON.stringify(data));
  const VERSION = 1;
  const parsed_data = {
    firstName: data.firstName,
    lastName: data.lastName,
    birthDate: reformatDateString(data.dob),
    email: data.email,
    phoneNumber: data.phone,
    address: data.homeless ? "Homeless" : (data.address + " " + data.suite).trim(),
    city: data.city,
    zip: data.zip,
    state: data.state,
    gender: data.gender,
    race: data.race,
    ethnicity: data.ethnicity,
    pregnant: (data.pregnancy === "Pregnant"),
    homeless: data.homeless,
    disabled: (data.disability === "Yes"),
    canEmail: data.county_email,
    preferEncrypted: data.email_encryption,
    preferredLanguage: data.preferredLanguage
  }

  let json_str = `ver${VERSION}\n` + JSON.stringify(parsed_data)
  return json_str;
}

function CountyForm({ onSubmit, schema, initialValues, children, formTitle, enableEmailValidation, enableSchemaValidation, ...props }) {
  let onSubmitFn = onSubmit ?? console.log;
  const submitLabel = props.submitLabel ?? 'Submit for QR Code';

  return (
    <Formik
      validationSchema={enableSchemaValidation ? schema : null}
      onSubmit={onSubmitFn}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <IntroSection />
          <NameAndContactSection handleChange={handleChange} handleBlur={handleBlur} values={values} touched={touched} errors={errors} emailVerificationEnabled={enableEmailValidation} />
          <hr />
          <DemographicSection handleChange={handleChange} handleBlur={handleBlur} values={values} touched={touched} errors={errors} />
          <hr />
          <ConsentSection handleChange={handleChange} handleBlur={handleBlur} values={values} touched={touched} errors={errors} />
          <Button type="submit">{submitLabel}</Button>
          <ScrollToError />
        </FormikForm>
      )
      }
    </Formik >
  );
};

function CountyQRForm({ onSubmit, formTitle, enableEmailValidation, enableSchemaValidation }) {
  let headerTitle = formTitle ?? "San Diego County COVID Testing Sign-up Form";

  const defaultInitVals = {
    firstName: '',
    lastName: '',
    dob: '10/01/1985',
    email: '',
    phone: '',
    address: '',
    suite: '',
    city: 'San Diego',
    state: 'CA',
    zip: '',
    gender: '',
    race: '',
    ethnicity: '',
    preferredLanguage: 'eng',
    disability: '',
    employmentType: 'Other',
    pregnancy: '',
    guardian: 'N/A',
    homeless: false,
    county_email: true,
    email_encryption: true,
    consent1: false,
    consent2: false,
  };

  const [state, setState] = useState({
    data_ready: false,
    data: defaultInitVals,
    caption: 'blank'
  });

  // Optional email validation hook when the state changes
  useEffect(() => {
    if (enableEmailValidation) {
      if (state.data_ready) {
        trackPromise(
          sendQRCodeViaEmail(state.data.email, CountyJSONSerializer(state.data))
            .then(res => {
              console.log("Returned from sendQRCodeViaEmail: ", res);
            })
            .catch(err => console.error(err))
        )
      }
    }
  }, [state, enableEmailValidation])

  // submit handler
  const handleSubmit = (data) => {
    const caption = data.firstName + " " + data.lastName + " / dob:" + data.dob;
    setState({ data_ready: true, data: data, caption: caption });

    if (onSubmit) {
      onSubmit(data);
    }

    simulateBrowserBack(() => {
      setState((prev_state) => {
        return { data_ready: false, caption: 'blank', data: prev_state.data };
      });
    });
  };

  const QRGeneratedLanding = ({ QRData, QRCaption }) => {
    return (
      <div>
        <h5 className="form-header">Your check-in is complete! </h5>
        <Alert>Please proceed to the next available desk and present this QR code to a staff member.</Alert>
        <QRCode text={QRData} size="320" caption={QRCaption} />
      </div>
    )
  }

  const QRSentLanding = ({ sentToEmail }) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      <>
        {promiseInProgress ?
          <div>
            < h5 className="form-header" > Processing your request...</h5 >
            <ThreeDots color="#4A90E2" height="100" width="100" />
          </div >
          :
          <div>
            <h5 className="form-header">Your check-in request has been successfully submitted! / ¡Su solicitud de registro ha sido enviada con éxito!</h5>
            <Alert variant='success'>
              <p>
                A QR code has been sent to your email ({sentToEmail}), please have this QR code ready for a staff when asked.
              </p>
              <p>
                Didn't get your email?  We recomend the following:
              </p>
              <ul>
                <li>Check your spam/junk folder, the email will be from donotreply@mobilexpressclinics.com</li>
                <li>Go back, double check your email address provided and resubmit to trigger another email request</li>
              </ul>
              <hr />
              <p>
                Se ha enviado un código QR a su correo electrónico ({sentToEmail}), diríjase al siguiente escritorio disponible y presente el código QR a un miembro del personal.
              </p>
              <p>
                ¿No recibiste tu correo electrónico? Recomendamos lo siguiente:
              </p>
              <ul>
                <li>Revise su carpeta de spam/basura, el correo electrónico será de donotreply@mobilexpressclinics.com</li>
                <li>Regrese, verifique dos veces la dirección de correo electrónico proporcionada y vuelva a enviar para activar otra solicitud de correo electrónico</li>
              </ul>
            </Alert>
          </div>}
      </>)
  }

  return (<>
    <h4 className="page-header">{headerTitle}</h4>
    {state.data_ready ?
      enableEmailValidation ? <QRSentLanding sentToEmail={state.data.email} /> : <QRGeneratedLanding QRData={CountyJSONSerializer(state.data)} QRCaption={state.caption} />
      :
      <CountyForm onSubmit={handleSubmit} schema={schema} initialValues={state.data} enableEmailValidation={enableEmailValidation} enableSchemaValidation={enableSchemaValidation} />
    }
  </>);
}

export { CountyQRForm, CountyJSONSerializer };