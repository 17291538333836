import * as yup from "yup";
import { parse, isDate } from "date-fns";

const ValidationRegex = {
    // phone number with area code or international format is allowed, no chars allowed
    phone: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,

    // standard email regex
    //eslint-disable-next-line
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    // no comma allowed
    address: /^[^,]+$/,
    // city, no comma and no numbers
    city: /[A-Za-z ]+$/,

    // only 2 letter states allowed
    state: /^[A-Z]{2}$/,
    // only 5 digit zip
    zip: /^\d{5}$/,

    // no illegal chars in names
    //eslint-disable-next-line
    name: /^([ \u00c0-\u01ffa-zA-Z'\-])+$/
}

function parseDateString(value, originalValue) {
    const parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "MM/dd/yyyy", new Date());
    return parsedDate;
}

const today = new Date();

const ValidationRules = {
    dobRule: yup.date().transform(parseDateString).min(new Date("1900-01-01"), "Birthdate cannot be earlier than 1900-01-01").max(today, "You cannot be born today or in the future").required('Date of birth is required.'),

    emailRule: yup.string().when(
        'homeless', {
        is: false,
        then: yup.string().required(),
    }).matches(ValidationRegex.email, 'Must be a valid email!'),

    phoneRule: yup.string().when(
        'homeless', {
        is: false,
        then: yup.string().required(),
    }).matches(ValidationRegex.phone, 'Must be a valid phone number!'),

    addressRule: yup.string().when('homeless', {
        is: false,
        then: yup.string().required("Address is required unless you are homeless"),
    }).matches(ValidationRegex.address, 'Address cannot contain commas, use space instead'),
}

export { ValidationRegex, ValidationRules }