import { Modal, InputGroup, Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { useState, useRef, useEffect } from 'react';

import { CovidConsent } from '../ConsentForms/ConsentForm';

// A check form that only gets checked if the EULA is accepted
function ConsentCheck({ label, name, checked, onChange, required, license_text, ...props }) {
    let text = license_text ?? "Text";
    let labelToUse = label ?? 'I agree to the terms and conditions';

    const [state, setState] = useState({ license_visible: false, license_accepted: false });
    const checkDOM = useRef(null);

    const handleCheck = (e) => {
        onChange(e);

        if (e.target.checked && !state.license_accepted) {
            setState({ license_visible: true, license_accepted: false });
        }
        if (e.target.checked && state.license_accepted) {
        }
        if (!e.target.checked) {
            setState({ license_visible: false, license_accepted: false });
        }
    }

    const handleAccept = (e) => {
        setState({ license_visible: false, license_accepted: true });
    }

    const handleDecline = (e) => {
        setState({ license_visible: false, license_accepted: false });
        // dispatch an event to onChange handler
        checkDOM.current.checked = false
        const event = new CustomEvent('declinedConsent', { bubbles: false, cancelable: true, detail: "user declined consent" });
        checkDOM.current.dispatchEvent(event);
    }
    // register a custom event handler for the DOM element check
    useEffect(() => {
        if (checkDOM != null && checkDOM.current != null) {
            checkDOM.current.addEventListener('declinedConsent', onChange);
        }
    }, [onChange]);



    return (
        <>
            <Form.Check
                required={required}
                name={name}
                label={labelToUse}
                checked={checked}
                onChange={handleCheck}
                ref={checkDOM}
                feedback={props.feedback}
                feedbackType={props?.feedbackType}
                isInvalid={props?.isInvalid}
                feedbackTooltip={props?.feedbackTooltip}
            />

            <Modal show={state.license_visible} size="lg">
                <Modal.Body>{text}</Modal.Body>
                <Modal.Footer className="mb-5">
                    <Button variant="outline-success" onClick={handleAccept}>
                        Accept
                    </Button>
                    <Button variant="outline-secondary" onClick={handleDecline}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
    // assume we are working with US phone numbers
    if (value.length > 10) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

function NameAndContactSection({ emailVerificationEnabled, handleChange, handleBlur, values, errors, touched, ...props }) {

    const handlePhoneChange = (e) => {
        const formatted = formatPhoneNumber(e.target.value);
        e.target.value = formatted;
        handleChange(e);
    }

    return (<>
        <h5 className="form-header">Basic Information</h5>
        <Row className="mb-3">
            <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik101"
                className="position-relative"
            >
                <Form.Label>First name</Form.Label>
                <Form.Control
                    type="text"
                    name="firstName"
                    value={values.firstName}
                    placeholder="First name..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.firstName && !errors.firstName}
                    isInvalid={touched.firstName && !!errors.firstName}
                />
            </Form.Group>
            <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik102"
                className="position-relative"
            >
                <Form.Label>Last name</Form.Label>
                <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last name..."
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.lastName && !errors.lastName}
                    isInvalid={touched.lastName && !!errors.lastName}
                />
            </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="emailGroup"
                className="position-relative"
            >
                <Form.Label>Email</Form.Label>
                <InputGroup>
                    <InputGroup.Text id="inputGroupPrepend">📧</InputGroup.Text>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="adam.smith@gmail.com"
                        aria-describedby="inputGroupPrepend"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && !!errors.email}
                    />
                    {
                        emailVerificationEnabled &&
                        <Form.Control.Feedback type="valid">Your registration QR code will be sent to this email, please double check for accuracy</Form.Control.Feedback>
                    }
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationFormikUsername2">
                <Form.Label>Phone</Form.Label>
                <InputGroup>
                    <InputGroup.Text id="inputGroupPrepend">📞</InputGroup.Text>
                    <Form.Control
                        type="tel"
                        placeholder="i.e. 858-123-4321"
                        aria-describedby="inputGroupPrepend"
                        name="phone"
                        value={values.phone}
                        onChange={handlePhoneChange}
                        onBlur={handleBlur}
                        isInvalid={touched.phone && !!errors.phone}
                        isValid={touched.phone && !errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.phone}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        </Row>

        <Row className="mb-3">
            <Form.Group as={Col} md="12">
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="100 Street Name..."
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.address && !!errors.address}
                />
                <Form.Control.Feedback type="invalid"> {errors.address} </Form.Control.Feedback>
            </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group as={Col} md="12">
                <Form.Label>Suite, Apartment, Unit..</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Suite or Apartment or Unit ..."
                    name="suite"
                    value={values.suite}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.suite && !!errors.suite}
                />
            </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik103"
                className="position-relative"
            >
                <Form.Label>City</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="City"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.city && !!errors.city}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.city}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formGridState" className="position-relative">
                <Form.Label>State</Form.Label>
                <Form.Select placeholder="Choose..." name="state" className="form-control" value={values.state}
                    isInvalid={touched.state && !!errors.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option>Choose...</option>
                    <option value="AL">Alabama (AL)</option>
                    <option value="AK">Alaska (AK)</option>
                    <option value="AZ">Arizona (AZ)</option>
                    <option value="AR">Arkansas (AR)</option>
                    <option value="CA">California (CA)</option>
                    <option value="CO">Colorado (CO)</option>
                    <option value="CT">Connecticut (CT)</option>
                    <option value="DE">Delaware (DE)</option>
                    <option value="DC">District Of Columbia (DC)</option>
                    <option value="FL">Florida (FL)</option>
                    <option value="GA">Georgia (GA)</option>
                    <option value="HI">Hawaii (HI)</option>
                    <option value="ID">Idaho (ID)</option>
                    <option value="IL">Illinois (IL)</option>
                    <option value="IN">Indiana (IN)</option>
                    <option value="IA">Iowa (IA)</option>
                    <option value="KS">Kansas (KS)</option>
                    <option value="KY">Kentucky (KY)</option>
                    <option value="LA">Louisiana (LA)</option>
                    <option value="ME">Maine (ME)</option>
                    <option value="MD">Maryland (MD)</option>
                    <option value="MA">Massachusetts (MA)</option>
                    <option value="MI">Michigan (MI)</option>
                    <option value="MN">Minnesota (MN)</option>
                    <option value="MS">Mississippi (MS)</option>
                    <option value="MO">Missouri (MO)</option>
                    <option value="MT">Montana (MT)</option>
                    <option value="NE">Nebraska (NE)</option>
                    <option value="NV">Nevada (NV)</option>
                    <option value="NH">New Hampshire (NH)</option>
                    <option value="NJ">New Jersey (NJ)</option>
                    <option value="NM">New Mexico (NM)</option>
                    <option value="NY">New York (NY)</option>
                    <option value="NC">North Carolina (NC)</option>
                    <option value="ND">North Dakota (ND)</option>
                    <option value="OH">Ohio (OH)</option>
                    <option value="OK">Oklahoma (OK)</option>
                    <option value="OR">Oregon (OR)</option>
                    <option value="PA">Pennsylvania (PA)</option>
                    <option value="RI">Rhode Island (RI)</option>
                    <option value="SC">South Carolina (SC)</option>
                    <option value="SD">South Dakota (SD)</option>
                    <option value="TN">Tennessee (TN)</option>
                    <option value="TX">Texas (TX)</option>
                    <option value="UT">Utah (UT)</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                </Form.Select>
            </Form.Group>

            <Form.Group
                as={Col}
                md="3"
                controlId="Zip"
                className="position-relative"
            >
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="i.e. 92000"
                    name="zip"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.zip && !!errors.zip}
                    isValid={touched.zip && !errors.zip}
                />
                <Form.Control.Feedback type="invalid"> {errors.zip} </Form.Control.Feedback>
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col} md="6">
                <Form.Label>Date of Birth (MM/DD/YYYY)</Form.Label>
                <Form.Control
                    type="text"
                    name="dob"
                    value={values.dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.dob && !!errors.dob}
                    isValid={touched.dob && !errors.dob}
                />
                <Form.Control.Feedback type="invalid"> {errors.dob} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik105"
                className="position-relative"
            >
                <Form.Label>Gender</Form.Label>
                <Form.Select
                    name="gender"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Choose.."
                    value={values.gender}
                    isInvalid={touched.gender && !!errors.gender}
                    className="form-control"
                >
                    <option value="">Choose..</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="transgender_mtf">Transgender M/F</option>
                    <option value="transgender_ftm">Transgender F/M</option>
                    <option value="declined">Declined</option>
                    <option value="unknown">Unknown</option>
                </Form.Select>
            </Form.Group>
        </Row>
    </>);
}


function CountyFields({ handleChange, handleBlur, values, errors, touched, ...props }) {
    return (
        <>
            <Row className="mb-3">
                <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationFormik105"
                    className="position-relative"
                >
                    <Form.Label>Preferred Language</Form.Label>
                    <Form.Select
                        name="preferredLanguage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="English"
                        value={values.preferredLanguage}
                        isInvalid={touched.preferredLanguage && !!errors.preferredLanguage}
                        className="form-control"
                    >
                        <option value="eng">English</option>
                        <option value="spa">Spanish</option>
                        <option value="zho">Chinese</option>
                        <option value="fra">French</option>
                        <option value="ita">Italian</option>
                        <option value="jpn">Japanese</option>
                        <option value="por">Portuguese</option>
                        <option value="rus">Russian</option>
                        <option value="other">Other</option>
                        <option value="declined">Declined</option>
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3 mt-2">
                <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationFormik105"
                    className="position-relative"
                >
                    <Form.Label>Pregnancy Status</Form.Label>
                    <br />
                    <Form.Select
                        name="pregnancy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="N/A"
                        value={values.pregnancy}
                        isInvalid={touched.pregnancy && !!errors.pregnancy}
                        className="form-control"
                    >
                        <option value="N/A">N/A</option>
                        <option value="Pregnant">Pregnant</option>
                        <option value="Non-pregnant">Non-Pregnant</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationFormik105"
                    className="position-relative"
                >
                    <Form.Label>Disability Status</Form.Label>
                    <Form.Select
                        name="disability"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Choose.."
                        value={values.disability}
                        isInvalid={touched.disability && !!errors.disability}
                        className="form-control"
                    >
                        <option value="">Choose..</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Declined">Declined</option>
                    </Form.Select>
                </Form.Group>
            </Row>

            <Row className="mb-3 mt-2">
                <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationFormik105"
                    className="position-relative"
                >
                    <Form.Label>Employment Type</Form.Label>
                    <Form.Select
                        name="employmentType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Other"
                        value={values.employmentType}
                        isInvalid={touched.employmentType && !!errors.employmentType}
                        className="form-control"
                    >
                        <option value="Other">Other</option>
                        <option value="First Responder">First Responder</option>
                        <option value="Healthcare Personnel">Healthcare Personnel</option>
                        <option value="Homeless Shelter Worker">Homeless Shelter Worker</option>
                    </Form.Select>
                </Form.Group>
            </Row>

            <Row className="mb-3 mt-5">
                <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationFormik105"
                    className="position-relative"
                >
                    <Form.Check
                        name="homeless"
                        label="Are you currently experiencing homelessness?"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.homeless && !!errors.homeless}
                        id="homeless"
                    />
                    <Form.Check
                        name="county_email"
                        label="May the county of San Diego email your test results?"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="county_email"
                        value={values.county_email}
                        defaultChecked
                    />
                    <Form.Check
                        name="email_encryption"
                        label="Do you prefer test results as an encrypted email attachment? (recommended)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="email_encryption"
                        value={values.email_encryption}
                        defaultChecked
                    />
                </Form.Group>
            </Row>
        </>
    );
}

function DemographicSection({ handleChange, handleBlur, values, errors, touched, hideCountyFields, ...props }) {
    hideCountyFields = hideCountyFields ?? false;

    return (<>
        <h5 className="form-header">Demographic Information</h5>

        <Row className="mb-3">
            <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik105"
                className="position-relative"
            >
                <Form.Label>Race</Form.Label>
                <Form.Select
                    name="race"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Choose.."
                    value={values.race}
                    isInvalid={touched.race && !!errors.race}
                    className="form-control"
                >
                    <option value="">Choose..</option>
                    <option value="asian">Asian</option>
                    <option value="indian">American Indian / Alaskan</option>
                    <option value="hawaiian">Pacific Islander</option>
                    <option value="black">Black / African American</option>
                    <option value="white">White</option>
                    <option value="other">Other</option>
                    <option value="blank">Unknown</option>
                    <option value="declined">Decline</option>
                </Form.Select>
            </Form.Group>
            <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik105"
                className="position-relative"
            >
                <Form.Label>Ethnicity</Form.Label>
                <Form.Select
                    name="ethnicity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Choose.."
                    value={values.ethnicity}
                    isInvalid={touched.ethnicity && !!errors.ethnicity}
                    className="form-control"
                >
                    <option value="">Choose..</option>
                    <option value="hispanic">Hispanic / Latino</option>
                    <option value="not_hispanic">Non-Hispanic / Latino</option>
                    <option value="declined">Decline</option>
                    <option value="blank">N/A</option>
                </Form.Select>
            </Form.Group>
        </Row>

        {hideCountyFields ? null : <CountyFields handleChange={handleChange} handleBlur={handleBlur} values={values} errors={errors} touched={touched} />}
    </>
    );
}
function IntroSection() {
    return (
        <>
            <Alert variant="dark" className="small">
                Please fill out this form for a QR code to register. We respect your privacy, information entered here is treated as confidential and will not be shared with any third parties.
            </Alert>
        </>
    )
}

function ConsentSection({ handleChange, handleBlur, values, errors, touched, ...props }) {
    return (
        <>
            <h5 className="form-header">Consents & Agreements</h5>
            <Row>
                <Form.Group as={Col} md="12">
                    <Form.Label>
                        I am the Parent/Legal Guardian of a minor being tested and hereby give my consent.
                    </Form.Label>
                    <Form.Select
                        name="guardian"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="N/A"
                        value={values.guardian}
                        isInvalid={touched.guardian && !!errors.guardian}
                        className="form-control"
                    >
                        <option value="N/A">N/A</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} className="mb-3">
                    <ConsentCheck
                        name="consent1"
                        label="I have read both the COVID-19 Consent and the HIPAA Data Use Authorization, and agree to the consents (Check to open)"
                        onChange={handleChange}
                        checked={values.consent1}
                        isInvalid={touched.consent2 && !!errors.consent1}
                        feedback={errors.consent1}
                        feedbackType="invalid"
                        id="consent1"
                        className="mb-3"
                        license_text={<CovidConsent />}
                    />
                    <Form.Check
                        name="consent2"
                        label="I acknowldege that I have read, understand, agree, certify, and/or authorize the information above and further agree to hold harmless Mobile Xpress Clinics and their affiliate laboratories, including its employees, agents, and contractors from any and all liability and claims."
                        onChange={handleChange}
                        checked={values.consent2}
                        isInvalid={touched.consent2 && !!errors.consent2}
                        feedback={errors.consent2}
                        feedbackType="invalid"
                        className="mb-3"
                        id="consent2"
                    />
                </Form.Group>
            </Row>
        </>
    )
}

export { ConsentCheck, NameAndContactSection, DemographicSection, ConsentSection, IntroSection };
