import React from 'react';
import Container from 'react-bootstrap/Container';
import { CountyQRForm } from './TestingSites/CountyForm';
import { MxcQRForm } from './TestingSites/MXCForm';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const App = () => (
  <Container className="p-3">
    <BrowserRouter>
      <Routes>
        <Route path="/mxc" element={<MxcQRForm />} />
        <Route path="/rrt" element={<CountyQRForm />} />
        <Route path="*" element={<CountyQRForm enableEmailValidation enableSchemaValidation />} />
      </Routes>
    </BrowserRouter>
  </Container>
);

export default App;