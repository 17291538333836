import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator} from 'firebase/functions';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAbTUgWFptsyrUex8CAyK5zNEq-t0mGeCY",
    authDomain: "mxcservices.com",
    projectId: "mxc-qr-registration",
    storageBucket: "mxc-qr-registration.appspot.com",
    messagingSenderId: "765005051408",
    appId: "1:765005051408:web:301e22ffa18cff48b8fc95"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    console.log("dev mode: using local cloud function (emulator)")
    connectFunctionsEmulator(functions, "localhost", 5001)
}
const verifyEmail = httpsCallable(functions, 'verifyEmail');

export { app, functions, verifyEmail } 
