import { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Alert, Button } from 'react-bootstrap';
import * as yup from "yup";

import QRCode from "../Components/QRCode";
import { ScrollToError, reformatDateString, simulateBrowserBack } from '../Components/Utilities';
import { IntroSection, NameAndContactSection, ConsentSection, DemographicSection } from '../Components/FormSections';
import { ValidationRegex, ValidationRules } from './Common';

const schema = yup.object().shape({
    firstName: yup.string().matches(ValidationRegex.name, 'Name cannot contain illegal characters such as #!/$?').required(),
    lastName: yup.string().matches(ValidationRegex.name, 'Name cannot contain illegal characters such as #!/$?').required(),
    dob: ValidationRules.dobRule,
    email: yup.string().matches(ValidationRegex.email, 'Must be a valid email!').required(),
    phone: yup.string().matches(ValidationRegex.phone, 'Must be a valid phone number!').required(),
    address: yup.string().matches(ValidationRegex.address, 'Address cannot contain commas, use space instead').required(),
    suite: yup.string().optional(),
    city: yup.string().matches(ValidationRegex.city, 'City name contains non-alphabets').required("City is required."),
    state: yup.string().matches(ValidationRegex.state, 'Must be a valid state choice').required("State is required."),
    zip: yup.string().matches(ValidationRegex.zip, 'Only 5 digit numerical zip is allowed.').required("Zip code is required."),
    gender: yup.string().required(),
    guardian: yup.string().matches(/^(?!.*No).*/, 'We cannot proceed without your consent.').optional(),
    consent1: yup.bool().required().oneOf([true], 'You must consent to the HIPAA data use authorization to continue'),
    consent2: yup.bool().required().oneOf([true], 'You must accept the release of liability to continue')
});



// converts data into a JSON serialized string with a version header
function MXCJsonSerializer(data) {
    console.log(JSON.stringify(data));
    const VERSION = 1;
    const parsed_data = {
        firstName: data.firstName,
        lastName: data.lastName,
        birthDate: reformatDateString(data.dob),
        email: data.email,
        phoneNumber: data.phone,
        address: (data.address + " " + data.suite).trim(),
        city: data.city,
        zip: data.zip,
        state: data.state,
        gender: (data.gender === "female" || data.gender === "male") ? data.gender : "unknown",
        race: data.race,
        ethnicity: data.ethnicity,
        pregnant: (data.pregnancy === "Pregnant"),
        homeless: data.homeless,
        disabled: (data.disability === "Yes"),
        canEmail: data.county_email,
        preferEncrypted: data.email_encryption,
        preferredLanguage: data.preferredLanguage
    }

    let json_str = `ver${VERSION}\n` + JSON.stringify(parsed_data)
    return json_str;
}


function MXCForm({ onSubmit, schema, initialValues, children, ...props }) {
    let onSubmitFn = onSubmit ?? console.log;
    const submitLabel = props.submitLabel ?? 'Submit for QR Code';

    return (
        <Formik
            validationSchema={schema}
            onSubmit={onSubmitFn}
            initialValues={initialValues}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors
            }) => (
                <FormikForm onSubmit={handleSubmit}>
                    <IntroSection />
                    <NameAndContactSection handleChange={handleChange} handleBlur={handleBlur} values={values} touched={touched} errors={errors} />
                    <hr />
                    <DemographicSection handleChange={handleChange} handleBlur={handleBlur} values={values} touched={touched} errors={errors} hideCountyFields />
                    <hr />
                    <ConsentSection handleChange={handleChange} handleBlur={handleBlur} values={values} touched={touched} errors={errors} />
                    <Button type="submit">{submitLabel}</Button>
                    <ScrollToError />
                </FormikForm>
            )
            }
        </Formik >
    );
}

function MxcQRForm({ onSubmit, headerText }) {
    let formHeader = headerText ?? "Mobile Xpress Clinics Check-in Form";

    const defaultInitVals = {
        firstName: '',
        lastName: '',
        dob: '10/01/1985',
        email: '',
        phone: '',
        address: '',
        suite: '',
        city: 'San Diego',
        state: 'CA',
        zip: '',
        gender: '',
        race: '',
        ethnicity: '',
        preferredLanguage: 'eng',
        disability: '',
        employmentType: 'Other',
        pregnancy: '',
        guardian: 'N/A',
        homeless: false,
        county_email: true,
        email_encryption: true,
        consent1: false,
        consent2: false,
    };

    const [state, setState] = useState({
        data_ready: false,
        data: defaultInitVals,
        caption: 'blank'
    });

    // submit handler
    function handleSubmit(data) {
        const caption = data.firstName + " " + data.lastName + " / dob:" + data.dob;
        setState({ data_ready: true, data: data, caption: caption });

        if (onSubmit) {
            onSubmit(data);
        }

        simulateBrowserBack(() => {
            setState((prev_state) => {
                return { data_ready: false, caption: 'blank', data: prev_state.data };
            });
        });
    }

    return (<>
        <h4>{formHeader}</h4>
        {state.data_ready ?
            <div>
                <h5 className="form-header">Your check-in is complete! </h5>
                <Alert>Please proceed to the next available desk and present this QR code to a staff member.</Alert>
                <QRCode text={MXCJsonSerializer(state.data)} size="320" caption={state.caption} />
            </div>
            :
            <MXCForm onSubmit={handleSubmit} schema={schema} initialValues={state.data} />}
    </>);
}


export { MxcQRForm, MXCJsonSerializer };