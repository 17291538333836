
import React, { useRef, useEffect } from 'react';
import QRious from 'qrious';
import { Figure } from 'react-bootstrap';

// Generates a QR code of size `size` with label `caption` conding given `text`
const QRCode = ({ text, size, caption }) => {
  const canvas = useRef(null);
  useEffect(() => {
    if (canvas != null && canvas.current != null) {
      let qr = new QRious({
        element: canvas.current,
        value: text,
        size: size
      });
      console.log("QR code created - text: \n" + text + " obj:" + qr);
    }
  });
  return (
    <div className="text-center">
      <Figure>
        <canvas ref={canvas} className="qr-code"></canvas>
        <Figure.Caption >
          {caption}
        </Figure.Caption>
      </Figure>
    </div>
  );
}

export default QRCode;
