import ReactMarkdown from 'react-markdown'

// TODO, revisit this, I think we can bundle the .md files as part of the webpack bundler and just load it and render it
const Covid_markdown = `##### Consent to COVID-19 Testing

**I authorize the use of my oropharyngeal / nasopharyngeal / mid-turbinate swab and / or saliva specimen for COVID-19 Testing.  I further understand, agree, certify, and authorize the following**:

1. Mobile Xpress Clinics (MXC) has contracted with a licensed Medical Professional for the collection of my specimen(s). I authorize the assigned licensed Medical Professional or his/her assignee to collect the specimen.
2. Mobile Xpress Clinics is authorized to send my sample to the appropriate  CAP/CLIA Accredited Laboratory for laboratory analysis and report of my specimen. I authorize these laboratories to perform testing on my specimen.
3. I understand that processing of the specimen and results may take between 1 to 3 days.  I further understand that whilst the testing laboratories have conducted extensive validation studies there is still potential for uninformative results or incorrect results.
4. I authorize the laboratory to release test results or other information as necessary to the Centers for Disease Control and Prevention (CDC) and The California Reportable Disease Information Exchange (CalREDIE).
5. I understand that the test results may provide information that could impact my own and other family members’ health, including the risk of developing a particular condition.

##### HIPAA data use authorization
**I understand that:**

1. Protected health information may be disclosed or used for treatment, payment, or healthcare operations.
2. Individuals with a positive test result should immediately seek counsel from their Healthcare Provider and Employer, if applicable.
3. MXC reserves the right to change the privacy policy as allowed by law.
4. MXC has the right to restrict the use of the information but the practice does not have to agree to those restrictions.
5. The patient has the right to revoke this consent in writing at any time and all full disclosures will then cease.
6. MXC may condition receipt of treatment upon execution of this consent.`

const CovidConsent = () => {
	return (<ReactMarkdown children={Covid_markdown} className="small" />);
}

export { CovidConsent };