import { verifyEmail } from "../FirebaseConfig";

function sendQRCodeViaEmail(email_to, qr_data) {
    const mailDetails = {
        from: "donotreply@mobilexpressclinics.com",
        to: email_to,
        subject: "Here's your registration QR code / Aquí está su código QR de registro",
        html: `
            Hello!<br>
Your check-in is complete! <br>
Please proceed to the next available desk and present this QR code to a staff member: <br>

¡Hola!<br>
¡Tu registro está completo! <br>
Diríjase al siguiente mostrador disponible y presente este código QR a un miembro del personal: <br>

<img src="cid:qr_img"/>
`
    }

    const data = {
        mailDetails: mailDetails,
        QRdata: qr_data
    }
    return verifyEmail(data);
}

export { sendQRCodeViaEmail }