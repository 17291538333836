import { useFormikContext } from 'formik';
import { useEffect } from 'react';

function ScrollToError() {
    const formik = useFormikContext();
    // check for errors when validating (last step before submission)
    const validating = formik?.isSubmitting;

    useEffect(() => {
        const el = document.querySelector('.is-invalid');
        (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [validating]);
    return null;
}

function reformatDateString(dateString) {
    //reformat date string to from YYYY-MM-DD to MM/DD/YYYY
    if (dateString && dateString.indexOf('-') > -1) {
        const dateParts = dateString.split('-');
        return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
    }
    return dateString;
}


// Simulate a browser "click" so the back button calls the callback we define here
const simulateBrowserBack = (callback) => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
        window.history.pushState(null, "", window.location.href);
        callback();
    };
};



export { ScrollToError, reformatDateString, simulateBrowserBack };